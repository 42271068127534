
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    thumbnail: {
      type: String,
      default: undefined
    },
    content: {
      type: String,
      default: undefined
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    return {
      addDefaultSrc
    }
  }
})
