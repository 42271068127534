<template>
  <div>
    <div class="relative" v-if="thumbnail">
      <div
        v-if="isLoadingCreative"
        class="absolute flex h-full items-center justify-center w-full bg-gray-500 opacity-50">
        <i class="fas fa-spin fa-spinner fa-2x text-white"></i>
      </div>
      <img
        :src="thumbnail"
        class="rounded-tl-lg rounded-tr-lg w-full"
        alt=""
        @error="addDefaultSrc"
      >
    </div>
    <p>{{ content }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    thumbnail: {
      type: String,
      default: undefined
    },
    content: {
      type: String,
      default: undefined
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    return {
      addDefaultSrc
    }
  }
})
</script>